about: |
    # About me

    I'm an active programmer and a tinkerer of all sorts.
    I am drawn to virtual learning environments and education technology in general.
    Recently graduated from the University of Jyväskylä with a Master's Degree in Education Technology.

    Currently, I am working at the University of Jyväskylä as a University Teacher.
    At the same time, I am working on my PhD thesis on using learning analytics to measure and
    support self-regulation skills of first-year Computer Science students.

    I'm an avid game modder and have an interest in reverse engineering.
    While I am generally interested in reverse engineering,
    I chiefly work with the Common Language Runtime (*CLR*) and Common Intermediate Language (*CIL*).

skills:
    - title: Education
      items:
          - prefix: "2021"
            title: MSc in Information Technology; Education Technology line
            description: University of Jyväskylä. Jyväskylä, Finland.
          - prefix: "2019"
            title: BSc in Information Technology
            description: University of Jyväskylä. Jyväskylä, Finland.
    - title: Other skills
      items:
          - prefix: "2021"
            title: Subject teacher qualification for teaching information technology and mathematics
            description: University of Jyväskylä, Department of Teacher Education.
    - title: Languages
      items:
          - prefix: ""
            title: Finnish
            description: |
                *Excellent*, passed as mother tongue in the Matriculation Examination
          - prefix: ""
            title: English
            description: |
                *Excellent*, passed as A1 language in the Matriculation Examination
          - prefix: ""
            title: Russian
            description: |
                *Excellent*, passed as A1 language in the Matriculation Examination
          - prefix: ""
            title: Swedish
            description: |
                *Satisfactory*, studied as B1 language
    - title: Work
      items:
          - prefix: "Aug 2021 –"
            rawPrefix: true
            title: University Teacher, University of Jyväskylä
            description: |
                - Managing and developing the [TIM](https://tim.jyu.fi/view/about/en-US) learning content management system.
                - Teaching introductory Computer Science courses like Programming and Databases and Data Management.
                - Working as technical guide for TIM-related projects in the Software Project course.
                - Designing exam materials for the Finnish digital entrance exams in Computer Science.
          - prefix: "Jan 2021 – Jul 2021"
            rawPrefix: true
            title: Technical Assistant, University of Jyväskylä
            description: |
                - Translating and evaluating learning materials for the [CodeInnova project](http://www.ci.unia.zam.pl/index.php/codeinnova-project)
                - Developing and maintaining the [TIM](https://tim.jyu.fi/view/about/en-US) learning content management system.
                - Designing and monitoring digital entrance exams for the Faculty of Information Technology.
          - prefix: "Mar 2020 – Aug 2020"
            rawPrefix: true
            title: "Research Assistant, University of Jyväskylä"
            description: |
                - Developing and maintaining the [TIM](https://tim.jyu.fi/view/about/en-US) learning content management system.
                - Focus on developing features to enable the systems's use in remote digital entrance exams.
          - prefix: "Nov 2020 – Dec 2020"
            rawPrefix: true
            title: "Teacher, Jyväskylä Educational Consortium Gradia"
            description: |
                - Taught an introductory web design course to Media and Visual Expression students.
                - Course contents: HTML5, CSS3, WordPress.
                - Demo of course materials: [Publishing on the web (in Finnish)](https://dezhidki.notion.site/Julkaiseminen-verkkosivulla-koti-demo-979cd3ad24574b71a26bc101c07855cc).
          - prefix: "Jan 2020 – Jun 2020"
            rawPrefix: true
            title: "Part-Time Teacher, University of Jyväskylä"
            description: |
                - Tutoring students in the *ITKP102 Programming 1* course of University of Jyväskylä.
    - title: Own publications
      items:
          - prefix: "Zhidkikh et al. (2024)"
            rawPrefix: true
            title: "Reproducing Predictive Learning Analytics in CS1: Toward Generalizable and Explainable Models for Enhancing Student Retention"
            description: |
                Journal of Learning Analytics, https://doi.org/10.18608/jla.2024.7979
          - prefix: "Zhidkikh, Saarela & Kärkkäinen (2023)"
            rawPrefix: true
            title: "Measuring self-regulated learning in a junior high school mathematics classroom: Combining aptitude and event measures in digital learning materials"
            description: |
                Journal of Computer Assisted Learning, https://doi.org/10.1111/jcal.12842
          - prefix: "Zhidkikh and Fagerlund et al. (2021)"
            rawPrefix: true
            title: '"CodeInnova": A Unified Framework for Teaching Programming and Computational Thinking In Primary Schools'
            description: |
                Poster in *Koli Calling ’21*, https://doi.org/10.1145/3488042.3489964
          - prefix: "Zhidkikh (2021)"
            rawPrefix: true
            title: "Investigating self-regulation in Finnish junior high school mathematics classes: a learning analytics case study"
            description: |
                Master Thesis in Education Technology, https://urn.fi/URN:NBN:fi:jyu-202106304100

projects:
    - title: The Interactive Material (TIM)
      description: |
          TIM (The Interactive Material) is a general-purpose learning content management
          system for producing highly interactive and open digital learning materials.
          It is tailored to teachers, students, material developers,
          and other content creators alike as an all-encompassing,
          centralised platform for creating and hosting document-based materials.

          TIM was originally created in 2014 by Vesa Lappalainen.
          I worked on TIM since 2021 as part of my work at the University of Jyväskylä.
          My main contributions to TIM are:

          - New theming system, allowing users to write and share themes.
          - New installation and management CLI.
          - Migrating TIM to GitHub and introducing a new CI pipeline.
          - Reorganization of the repo into a multi-repo structure.
          - Tools to create an manage TIM content locally.
          - Live status pages for users.

          **Languages used:** Python, TypeScript, Rust
      links:
          - type: gh
            link: TIM-JYU/TIM
          - type: gh
            link: JYU-DI/timsync
          - type: direct
            link: https://tim.jyu.fi/
            name: TIM front page
    - title: Unity Doorstop
      description: |
          A library to inject and bootstrap custom code into games running with Unity engine.  
          Compared to most other solutions common in Unity modding communities, this tool is a simple copy-paste library that requires no edits to the game itself.

          On Windows, the library makes use of simple [DLL Hijacking](https://attack.mitre.org/techniques/T1574/001/) and [IAT hooking](https://www.ired.team/offensive-security/code-injection-process-injection/import-adress-table-iat-hooking). 
          On Linux and macOS the library uses [`LD_PRELOAD`](http://www.goldsborough.me/c/low-level/kernel/2016/08/29/16-48-53-the_-ld_preload-_trick/) and `DYLD_LIBRARY_PATH` environment variables along with [plthook](https://github.com/kubo/plthook) library.

          **Languages used:** C
      image: doorstop
      links:
          - type: gh
            link: NeighTools/UnityDoorstop
          - type: gh
            link: NeighTools/UnityDoorstop.Unix
    - title: Tommy
      description: |
          A base library to read and write [TOML](https://github.com/toml-lang/toml) configuration file format.  
          Uses a basic hand-made parser to read file format into a JSON-like object for easy traversing.  Can be used as a base library for high-level serializers.  
          Includes a large unit test fixture for both reading and writing TOML.

          **Languages used:** C#
      image: tommy
      links:
          - type: gh
            link: dezhidki/Tommy
    - title: Escape Room Game Website for Mythbusters 2019 camp
      description: |
          Basic Escape Room Game website made for Myytinmurtajat 2019 (Mythbusters) camp organized at the University of Jyväskylä.
          The game was aimed at 7 to 9 year-old children.  
          In it, the children solved puzzles related to computer science in order to obtain a code to unlock a prize.

          The project included two websites: a code input website and a blog which contained a "reorder year numbers" puzzle.

          **Languages used:** Python, JavaScript  
          **Frameworks used:** Flask
      image: koodi
      links:
          - type: gh
            link: dezhidki/myytinmurtajat_it_19
          - type: direct
            link: https://koodi.deni.sh/
            name: koodi.deni.sh (code verification)
          - type: direct
            link: https://faniblogi.deni.sh/
            name: faniblogi.deni.sh (blog puzzle)
    - title: ZeroRPC.NET
      description: |
          This is a basic implementation of [zerorpc](https://www.zerorpc.io/) protocol for .NET.  
          This was my test in porting larger projects from one language to another.

          ZeroRPC.NET allows to define a simple RPC interface over which processes can communicate with each other.  
          ZeroRPC uses ZeroMQ for communication and MessagePack for data serialization.

          **Languages used:** C#
      links:
          - type: gh
            link: ghorsington/zerorpc-dotnet
