import "./css/tailwind.css";
import images from "./assets/*.png";
import "@fortawesome/fontawesome-free/css/all.css";
import aboutData from "./info.yaml"; 
import Alpine from "alpinejs";
import marked from "marked";

window.Alpine = Alpine;

async function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
};

async function getGhProjectStarCount(project) {
    const url = `https://api.github.com/repos/${project}`;
    const response = await fetch(url);
    const json = await response.json();
    return json.stargazers_count;
}

Alpine.magic("md", () => (md) => marked.marked(md, { headerIds: false, mangle: false }));

Alpine.data("main", () => ({
    titleText: './deni.sh_',
    aboutData: aboutData,
    showPage: false,
    tab: "about",
    images: images,

    init() {
        const t = this.titleText;
        this.titleText = '_' + ' '.repeat(t.length - 1);

        (async () => {
            await timeout(500);
            for (let i = 0; i < t.length; i++) {
                await timeout(50 + Math.random() * 30);
                this.titleText = t.substring(0, i) + '_' + ' '.repeat(t.length - i - 1);
            }
            await timeout(500);
            this.showPage = true;
        })();

        (async () => {
            const projects = this.aboutData.projects;
            for (const project of projects) {
                if (project.links) {
                    for (const link of project.links) {
                        if (link.type == "gh") {
                            link.stars = await getGhProjectStarCount(link.link);
                        }
                    }
                }
            }
        })();
    }
}));

Alpine.data("title", () => ({
    subtitles: [
        "<i>Nice!</i>",
        "Neigh!",
        "not an actual .sh script",
        "I'm not sure why you're here, but it's cool",
        "I'm not sure what you're looking for, but it's cool you're here",
        "you're a very nice person",
        "did you know that you can do math with your hands?",
        "magic happens in the background",
        "command not found",
        "h as in <a href='https://www.github.com/ghorsington'>🐴</a>"
    ],
    currentText: "",
    showText: true,

    changeSubtitle() {
        this.currentText = this.subtitles[Math.floor(Math.random() * this.subtitles.length)];
    },

    init() {
        this.changeSubtitle();
        setInterval(async () => {
            this.showText = false;
            await timeout(600);
            this.changeSubtitle();
            this.showText = true;
        }, Math.random() * 5000 + 10000);
    }
}));

Alpine.start();